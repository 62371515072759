import coverImage from "../donation1.png";

export default function Donation() {
  return (
    <div className="About">
      <img className="CoverImage" src={coverImage}></img>
      <h1 className="ImageText">Donation</h1>
    </div>
  );
}
